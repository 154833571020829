<div class="container justify-content-center mb-4">
    <div class="card col-12 justify-content-center">
        <div id="Preelaborado">
            <div>
                <div class="mb-3 mt-3" id="linea" style="background-color: #df0209;"></div>
                <div class="row align-items-center">
                    <div id="divimagen" class=" col-md-2 col-3 order-md-first text-center" style="padding-right: 0px;">
                        <img id="logoayuntamiento" src="{{url_escudo}}" alt="">
                    </div>
                    <div class="col-md-8 col-8 text-center">

                        <div class="text-center">
                            <h5 style="font-family: sans-serif;">{{this.ente}}</h5>
                            <h5 style="font-family: sans-serif;">TESORERIA MUNICIPAL - DIRECCIÓN DE INGRESOS</h5>
                            <Small style="font-family: sans-serif;">{{this.direcciones}}</Small>
                            <br>
                            <strong><label for="" style="font-family: sans-serif;">PREELEABORADO PARA PAGO EN EL
                                    BANCO</label></strong>
                        </div>
                    </div>
                    <div class="col-md-2 col-2 order-md-last text-center" style="padding-left: 0px;">
                        <img id="logoadministracion" src="{{url_administracion}}" alt="">
                    </div>
                </div>
                <div id="linea" style="background-color: #0b30ac;"></div>
                <!--Datos Contribuyente-->
                <div>
                    <div class="text-center">
                        <h5 style="font-family: serif;"><u>DATOS DEL CONTRIBUYENTE</u></h5>
                    </div>
                    <div>
                        <div class="row pt-2">
                            <div class="ml-5 col-5" id="datos">
                                <h6>Nombre: {{adeudo.Nombre_Propietario}}</h6>
                                <h6>Domicilio: {{adeudo.Domicilio_Propiedad}}</h6>
                            </div>
                            <div class="mr-5 col-5 align-items-end">
                                <h6>RFC: {{adeudo.RFC}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Datos Predio-->
                <div>
                    <div class="text-center">
                        <h5 style="font-family: serif;"><u>DATOS DEL PREDIO</u></h5>
                    </div>
                    <div>
                        <div class="row pt-2">
                            <div class="ml-5 col-5" id="datos">
                                <h6>Clave Catastral: {{adeudo.Cve_Catastral}}</h6>
                                <h6>Ubicacion: {{adeudo.Ubicacion_Propiedad}}</h6>
                                <h6>Colonia: {{adeudo.Colonia_Propiedad}}</h6>
                                <h6>Poblacion: {{adeudo.Poblacion_Propiedad}}</h6>
                                <h6>Municipio: {{this.municipio}}</h6>
                            </div>
                            <div class="mr-5 col-5">
                                <h6>Valor Catastral: {{adeudo.Valor_Catastral}}</h6>
                                <h6>Superficie de Terreno: {{adeudo.Superficie_Terreno}}</h6>
                                <h6>Superficie de Contrucción: {{adeudo.Superficie_Construccion}}</h6>
                                <h6>Superficie de Terreno: {{adeudo.Superficie_Terreno}}</h6>
                                <h6>Subsidio: {{adeudo.Tipo_Subsidio}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Datos Predio-->
                <div>
                    <div class="text-center">
                        <h5 style="font-family: serif;"><u>DATOS PARA PAGO</u></h5>
                    </div>
                    <div class="text-center">
                        <h5>IMPORTE TOTAL A PAGAR {{this.adeudo.Total | currency}}</h5>
                    </div>
                    <div>
                        <div class="row pt-2">
                            <div class="ml-5 col-5" id="datos">
                                <SMall><Label>Pago del impuesto predial urbano, periodo desde
                                        {{anio_desde}}-{{trimestre_desde}} hasta
                                        {{anio_hasta}}-{{trimestre_hasta}}</Label></SMall>
                                <h6>REFERENCIA BBVA: 00000100100100121429861217</h6>
                                <h6>EMPRESA: 0000</h6>
                                <h6>FECHA LIMITE DE PAGO: 11-ENERO-2021</h6>
                            </div>
                            <div class="mr-5 col-5 text-end">
                                <ngx-barcode [bc-height]="50" [bc-width]="1" [bc-value]="this.codigo"
                                    [bc-display-value]="true" [bc-font-size]="10"></ngx-barcode>
                            </div>
                        </div>
                    </div>
                    <small>COPIA CONTRIBUYENTE</small>
                </div>
            </div>
            <div>
                <div id="linea" style="background-color: #00913d;"></div>
                <div class="mb-3 mt-3" id="linea" style="background-color: #df0209;"></div>
                <div class="row align-items-center">
                    <div id="divimagen" class=" col-md-2 col-2 order-md-first text-center" style="padding-right: 0px;">
                        <img id="logoayuntamiento" src="{{url_escudo}}" alt="">
                    </div>
                    <div class="col-md-8 col-8 text-center">

                        <div class="text-center">
                            <h5 style="font-family: sans-serif;">{{this.ente}}</h5>
                            <h5 style="font-family: sans-serif;">TESORERIA MUNICIPAL - DIRECCIÓN DE INGRESOS</h5>
                            <Small style="font-family: sans-serif;">{{this.direcciones}}</Small>
                            <br>
                            <strong><label for="" style="font-family: sans-serif;">PREELEABORADO PARA PAGO EN EL
                                    BANCO</label></strong>
                        </div>
                    </div>
                    <div class="col-md-2 col-2 order-md-last text-center" style="padding-left: 0px;">
                        <img id="logoadministracion" src="{{url_administracion}}" alt="">
                    </div>
                </div>
                <div id="linea" style="background-color: #0b30ac;"></div>
                <!--Datos Contribuyente-->
                <div>
                    <div class="text-center">
                        <h5 style="font-family: serif;"><u>DATOS DEL CONTRIBUYENTE</u></h5>
                    </div>
                    <div>
                        <div class="row pt-2">
                            <div class="ml-5 col-5" id="datos">
                                <h6>Nombre: {{adeudo.Nombre_Propietario}}</h6>
                                <h6>Domicilio: {{adeudo.Domicilio_Propiedad}}</h6>
                            </div>
                            <div class="mr-5 col-5 align-items-end">
                                <h6>RFC: {{adeudo.RFC}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Datos Predio-->
                <div>
                    <div class="text-center">
                        <h5 style="font-family: serif;"><u>DATOS DEL PREDIO</u></h5>
                    </div>
                    <div>
                        <div class="row pt-2">
                            <div class="ml-5 col-5" id="datos">
                                <h6>Clave Catastral: {{adeudo.Cve_Catastral}}</h6>
                                <h6>Ubicacion: {{adeudo.Ubicacion_Propiedad}}</h6>
                                <h6>Colonia: {{adeudo.Colonia_Propiedad}}</h6>
                                <h6>Poblacion: {{adeudo.Poblacion_Propiedad}}</h6>
                                <h6>Municipio: {{this.municipio}}</h6>
                            </div>
                            <div class="mr-5 col-5">
                                <h6>Valor Catastral: {{adeudo.Valor_Catastral}}</h6>
                                <h6>Superficie de Terreno: {{adeudo.Superficie_Terreno}}</h6>
                                <h6>Superficie de Contrucción: {{adeudo.Superficie_Construccion}}</h6>
                                <h6>Superficie de Terreno: {{adeudo.Superficie_Terreno}}</h6>
                                <h6>Subsidio: {{adeudo.Tipo_Subsidio}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <!--Datos Predio-->
                <div>
                    <div class="text-center">
                        <h5 style="font-family: serif;"><u>DATOS PARA PAGO</u></h5>
                    </div>
                    <div class="text-center">
                        <h5>IMPORTE TOTAL A PAGAR {{this.adeudo.Total | currency}}</h5>
                    </div>
                    <div>
                        <div class="row pt-2">
                            <div class="ml-5 col-5" id="datos">
                                <SMall><Label>Pago del impuesto predial urbano, periodo desde:
                                        {{anio_desde}}-{{trimestre_desde}} hasta
                                        {{anio_hasta}}-{{trimestre_hasta}}</Label></SMall>
                                <h6>REFERENCIA BBVA: 00000100100100121429861217</h6>
                                <h6>EMPRESA: 0000</h6>
                                <h6>FECHA LIMITE DE PAGO: 11-ENERO-2021</h6>
                            </div>
                            <div class="mr-5 col-5 align-items-end">
                                <ngx-barcode [bc-height]="50" [bc-width]="1" [bc-value]="this.codigo"
                                    [bc-display-value]="true" [bc-font-size]="10"></ngx-barcode>
                            </div>
                        </div>
                    </div>
                    <small>COPIA BANCO</small>
                </div>
                <div class="mb-2" id="linea" style="background-color: #00913d;"></div>
            </div>
        </div>
    </div>