import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PadronService {

  constructor(private http: HttpClient) { }


  GetPadron(id_impuesto: string, id_padron: string) {
    var key = {
      "id_impuesto": id_impuesto,
      "id_padron": id_padron
    }
    return this.http.get<any>(environment.url_webapi + "GetDataPadron?key=" + btoa(JSON.stringify(key)));
  }

  GetPadronDetalle(id_impuesto: String, id_padron: number, anio_desde: string, mes_desde: string, anio_hasta: string, mes_hasta: string) {
    var key = {
      "id_impuesto": id_impuesto,
      "id_padron": id_padron,
      "anio_desde": anio_desde,
      "mes_desde": mes_desde,
      "anio_hasta": anio_hasta,
      "mes_hasta": mes_hasta
    }
    return this.http.get<any>(environment.url_webapi + "GetPadronDetalle?key=" + btoa(JSON.stringify(key)))

  }
}
