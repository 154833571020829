import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './Componentes/inicio/inicio.component';
import { SergioComponent } from './Componentes/Pruebas/sergio/sergio.component';
import { GabrielComponent } from './Componentes/Pruebas/gabriel/gabriel.component';
import { MiguelComponent } from './Componentes/Pruebas/miguel/miguel.component';
import { ConsultaAdeudoComponent } from './Componentes/Pagos/Predial/consulta-adeudo/consulta-adeudo.component';
import { AdeudoComponent } from './Componentes/Pagos/Predial/adeudo/adeudo.component';

import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { PreelaboradoComponent } from './Componentes/Pagos/Predial/preelaborado/preelaborado.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { LoginComponent } from './Componentes/login/login.component';
import { ConsultaDePagosComponent } from './Componentes/Pagos/Predial/consulta-de-pagos/consulta-de-pagos.component';

import { AngularFireModule } from '@angular/fire';
import { environment } from './../environments/environment';
import { ConsultaRegistrosComponent } from './Componentes/Pagos/Predial/consulta-registros/consulta-registros.component';
import { AcreditacionPagoComponent } from './Componentes/Pagos/acreditacion-pago/acreditacion-pago.component';
import { ReciboEnLineaComponent } from './Componentes/Pagos/Predial/recibo-en-linea/recibo-en-linea.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ConsultaAdeudoPadronComponent } from './Componentes/Pagos/PadronMercados/consulta-adeudo-padron/consulta-adeudo-padron.component';
import { AdeudoPadronComponent } from './Componentes/Pagos/PadronMercados/adeudo-padron/adeudo-padron.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import {MatNativeDateModule} from '@angular/material/core';
import { AdeudoPadronAlcoholesComponent } from './Componentes/Pagos/PadronAlcoholes/adeudo-padron-alcoholes/adeudo-padron-alcoholes.component';
import { ConsultaAdeudoPadronAlcoholesComponent } from './Componentes/Pagos/PadronAlcoholes/consulta-adeudo-padron-alcoholes/consulta-adeudo-padron-alcoholes.component';
import { ErrorPagoComponent } from './Componentes/Pagos/Predial/error-pago/error-pago.component';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    SergioComponent,
    GabrielComponent,
    MiguelComponent,
    ConsultaAdeudoComponent,
    AdeudoComponent,
    PreelaboradoComponent,
    LoginComponent,
    ConsultaDePagosComponent,
    ConsultaRegistrosComponent,
    AcreditacionPagoComponent,
    ReciboEnLineaComponent,
    ConsultaAdeudoPadronComponent,
    AdeudoPadronComponent,
    AdeudoPadronAlcoholesComponent,
    ConsultaAdeudoPadronAlcoholesComponent,
    ErrorPagoComponent,

  ],
  imports: [
    //Firebase
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxBarcodeModule,
    QRCodeModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatNativeDateModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
