import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consulta-adeudo-padron',
  templateUrl: './consulta-adeudo-padron.component.html',
  styleUrls: ['./consulta-adeudo-padron.component.scss']
})
export class ConsultaAdeudoPadronComponent implements OnInit {

  id_padron: string;
  validar: boolean = false;
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  ConsultarAdeudo(): void {
    if (this.Validar())
      this.router.navigate(["/AdeudoPadronMercado/" + this.id_padron])
  }

  Validar() {
    this.validar = true;
    if (this.id_padron == ""|| isNullOrUndefined(this.id_padron)) return false;
    return true;
  }
}
