<div class="container main col-12 pt-5 pb-5 ">
    <div class="container">
        <h5 class="text-center p-3">PADRON ALCOHOLES</h5>
        <div id="consulta">
            <div id="linea"></div>
            <h1 class="text-center p-3">CONSULTA TU PADRON</h1>
            <div id="linea"></div>
        </div>
        <p class="text-center p-3">"Ahorra tiempo, evita desplazamientos"</p>

        <div class=" row text-center pt-3">
            <div class=" col-4"></div>
            <input id="mun" class="form-control text-center col-4" [(ngModel)]="this.id_padron" placeholder="Padron">
            <div class="col-4"></div>
        </div>
        <div class="text-center pt-3">
            <button class="btn btn-danger" id="btnconsultar" (click)="ConsultarAdeudo()">Consultar <i class="fas fa-search pl-1"></i></button>
        </div>
    </div>
</div>

<div class="container" id="informacion">
    <div class="row">
        <div class="col-md-4 mb-3">
            <div class="card p-5">
                <img class="mx-auto" src="https://pagoselota.com.mx/img/iconoprocesopago.png" alt="" title=""
                    style="width: 120px; background-color: white; border-radius: 8%;">
                <p class="pt-3 text-center">¿Cómo realizo un pago en línea?</p>
            </div>
        </div>
        <div class="col-md-4 mb-3">
            <div class="card p-5">
                <img class="mx-auto" src="https://pagoselota.com.mx/img/iconoclavecatastral.png" alt="" title=""
                    style="width: 120px; background-color: white; border-radius: 8%;">
                <p class="pt-3 text-center">Ubica tu N° de padron</p>
            </div>
        </div>
        <div class="col-md-4 mb-3">
            <div class="card p-5">
                <img class="mx-auto" src="https://pagoselota.com.mx/img/iconoclavecatastral.png" alt="" title=""
                    style="width: 120px; background-color: white; border-radius: 8%;">
                <p class="pt-3 text-center">Consulta tu adeudo</p>
            </div>
        </div>
    </div>
</div>