import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-pago',
  templateUrl: './error-pago.component.html',
  styleUrls: ['./error-pago.component.scss']
})
export class ErrorPagoComponent implements OnInit {

  no_autorizacion: string;
  referencia_pago: string;
  folio_recibo: string;

  constructor(private route: ActivatedRoute, private router: Router) { }
  

  ngOnInit(): void {
  }

  
  Regresar() {
    this.router.navigate([""]);
  }

}
