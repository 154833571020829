import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PredialUrbanoService } from 'src/app/Sercivios/predial-urbano.service';
import { environment } from 'src/environments/environment';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { jsPDF } from "jspdf";
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-recibo-en-linea',
  templateUrl: './recibo-en-linea.component.html',
  styleUrls: ['./recibo-en-linea.component.scss']
})
export class ReciboEnLineaComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private pu_servive: PredialUrbanoService,
    private location: Location,
    private router: Router) { }

  municipio = "";
  ente = "";
  administracion = "";
  url_escudo = "";
  url_administracion = "";
  url_footer = "";
  direcciones = [];
  telefonos = [];
  emails = [];
  url_aviso = "";
  url_sitio = "";

  cve_cast = "";
  nombre = "";
  domicilio_ubicacion = "";
  colonia = "";
  cp = "";
  domicilio_notificacion = "";
  uso = "";
  sup_terreno = "";
  subsidio = "";
  sup_construccion = "";
  recibo_oficial = "";
  no_operacion = "";
  fecha_hora = "";
  oficina = "";
  caja = "0";
  usuario = "";
  origen = "";
  periodo_pago_desde = "";
  periodo_pago_hasta = "";
  valor_catastral = "";
  leyenda_recibo = "";
  leyenda_fundamento = "";

  principal = 0;
  asistencia = 0;
  jubilados = 0;
  r_fiscales = 0;
  multas = 0;
  redondeo = 0;
  total_recibo = 0;
  fecha_pago: Date;
  cadena_seguridad = ""
  QR = ""
  referencia_pago = "";
  recibo = "";
  contentDataURL: string;
  QRcodeURL: string;
  imgWidth: number;
  imgHeight: number;
  pageHeight: Number;
  heightLeft: Number;

  recibo_data: any;

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.recibo = params.recibo;
      this.referencia_pago = params.referencia;
    });

    this.pu_servive.GetRecibo_PU(this.recibo).subscribe(data => {
      this.recibo_data = data;

      this.cve_cast = this.recibo_data.clave_catastral.match(/\d{3}(?=\d{2,3})|\d+/g).join("-");
      this.nombre = this.recibo_data.nombre_propietario;
      this.domicilio_ubicacion = this.recibo_data.domicilio_ubicacion;
      this.colonia = this.recibo_data.colonia;
      this.cp = this.recibo_data.codigo_postal;
      this.domicilio_notificacion = this.recibo_data.domicilio_notificacion;
      this.uso = this.recibo_data.uso;
      this.sup_terreno = this.recibo_data.superficie_terreno;
      this.subsidio = this.recibo_data.subsidio;
      this.sup_construccion = this.recibo_data.superficie_construccion;
      this.recibo_oficial = this.recibo_data.recibo;
      this.no_operacion = this.recibo_data.no_operacion;
      this.fecha_hora = this.recibo_data.fecha_hora;
      this.oficina = this.recibo_data.oficina;
      this.caja = this.recibo_data.caja;
      this.usuario = this.recibo_data.usuario;
      this.origen = this.recibo_data.origen;
      this.periodo_pago_desde = this.recibo_data.periodo_desde.match(new RegExp('.{1,4}', 'g')).join("/")
      this.periodo_pago_hasta = this.recibo_data.periodo_hasta.match(new RegExp('.{1,4}', 'g')).join("/")
      this.valor_catastral = this.recibo_data.valor_catastral;

      for (var item of this.recibo_data.Detalle_recibo) {
        this.total_recibo += parseFloat(item.importe);
      }
      this.fecha_pago = new Date();
      this.cadena_seguridad = this.recibo_data.cadena_seguridad;
      this.QR = this.url_sitio + "ReciboLinea/" + this.recibo + "/" + this.referencia_pago;
    }, (error: HttpErrorResponse) => {
      alert("No se pudo cargar el recibo. Inténtelo más tarde")
      this.router.navigate([""]);
    });
    this.municipio = environment.municipio;
    this.ente = environment.ente;
    this.administracion = environment.administracion;
    this.url_escudo = environment.url_escudo;
    this.url_administracion = environment.url_administracion;
    this.url_footer = environment.url_footer;
    this.direcciones = environment.direcciones;
    this.telefonos = environment.telefonos;
    this.emails = environment.emails;
    this.url_aviso = environment.url_aviso;
    this.url_sitio = environment.url_sitio;
    this.leyenda_recibo = environment.leyenda_recibo;
    this.leyenda_fundamento = environment.leyenda_fundamento;
  }
  
  DescargarPDF() {
    html2canvas(document.querySelector('#Recibo'), { allowTaint: true, scrollX: 0, scrollY: -window.scrollY }).then(
      canvas => {
        let pdf = new jsPDF('p', 'mm', [297, 210]); // A4 tamaño a pdf 

        pdf.addImage(canvas.toDataURL(), 'PNG', 0, 0, 208, 285)
        //pdf.addImage(this.QRcodeURL, 'PNG', 15, 15, 25, 25)
        pdf.save("RECIBO DE PAGO EN LINEA PREDIAL URBANO");
      }
    );
  }
}
