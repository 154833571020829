<div class="container pt-3 pb-3" id="header">
    <div class="row align-items-center">
        <div class="col-md-2 col-6 order-md-first text-center">
            <img id="logoayuntamiento" src="{{url_escudo}}" alt="">
        </div>
        <div class="col-md-8 order-last text-center">
            <h1>{{ente}}</h1>
            <h1>{{administracion}}</h1>
        </div>
        <div class="col-md-2 col-6 order-md-last text-center">
            <img id="logoadministracion" src="{{url_administracion}}" alt="">
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="container col-12 pt-3 pb-3" id="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-3 text-center pb-3">
                <img id="logoadministracionfooter" src="{{url_footer}}" alt="">
            </div>
            <div class="col-md-3">
                <h4 class="pb-3">Direccion:</h4>
                <ul>
                    <li *ngFor="let direccion of direcciones">{{direccion}}</li>
                </ul>
            </div>
            <div class="col-md-3">
                <h4 class="pb-3">Números Telefónicos:</h4>
                <ul>
                    <li *ngFor="let telefono of telefonos">{{telefono}}</li>
                </ul>
            </div>
            <div class="col-md-3">
                <h4 class="pb-3">Escribenos A:</h4>
                <ul>
                    <li *ngFor="let email of emails">{{email}}</li>
                </ul>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-6 text-md-left text-center">
                <h5 id="administracion">{{ente}} {{administracion}}</h5>
            </div>
            <div class="col-md-6 text-md-right text-center">
                <a id="aviso" href="{{url_aviso}}" target="blank_">Aviso de privacidad</a>
            </div>
        </div>
    </div>
</div>