import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { Location } from '@angular/common';

@Component({
  selector: 'app-preelaborado',
  templateUrl: './preelaborado.component.html',
  styleUrls: ['./preelaborado.component.scss']
})
export class PreelaboradoComponent implements OnInit {
  adeudo = null;
  anio_desde = "";
  trimestre_desde = "";
  anio_hasta = "";
  trimestre_hasta = "";
  codigo = "00000100100100121429861217";

  constructor(private activate_router: ActivatedRoute, private router: Router, private location: Location) {
    activate_router.queryParams.subscribe(params => {
      this.adeudo = params["valor"];
    })
  }
  municipio = "";
  ente = "";
  administracion = "";
  url_escudo = "";
  url_administracion = "";
  url_footer = "";
  direcciones = [];
  telefonos = [];
  emails = [];
  url_aviso = "";

  ngOnInit(): void {
    this.Datos();
  }

  ngAfterViewInit() {
  window.scrollTo(0, 0);
   this.Convertir();
  this.location.back();
  }

  Datos() {
    this.municipio = environment.municipio;
    this.ente = environment.ente;
    this.administracion = environment.administracion;
    this.url_escudo = environment.url_escudo;
    this.url_administracion = environment.url_administracion;
    this.url_footer = environment.url_footer;
    this.direcciones = environment.direcciones;
    this.telefonos = environment.telefonos;
    this.emails = environment.emails;
    this.url_aviso = environment.url_aviso;

    this.adeudo = JSON.parse(this.adeudo);
    this.anio_desde = this.adeudo.Desde.substring(0, 4);
    this.trimestre_desde = this.adeudo.Desde.substring(4);
    this.anio_hasta = this.adeudo.Hasta.substring(0, 4);
    this.trimestre_hasta = this.adeudo.Hasta.substring(4);
    for (var item of this.adeudo.Detalle) {
      if (item.check) {
        this.anio_hasta = item.anio
        this.trimestre_hasta = item.trimestre
      }
    }
  }

  Convertir() {
    var data = document.getElementById('Preelaborado');
    html2canvas(data).then(canvas => {

      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 tamaño a pdf 
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)

      var blob = pdf.output("blob");
      var blob_url = URL.createObjectURL(blob);
      window.open(blob_url);
    });
  }
}

