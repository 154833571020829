<div *ngIf="!adeudo" class="container">
    <div class="progress">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
    </div>
    <p class="text-right">Cargando Información...</p>
</div>

<div *ngIf="adeudo">
    <div class="container">
        <div id="informacion">
            <div id="linea"></div>
            <h3 class="text-center p-3">Información de la clave</h3>
            <div id="linea"></div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-md-4 mb-4 mb-md-0">
                <div class="card p-2" id="contribuyente">
                    <div class="row justify-content-center">
                        <i class="fas fa-user-circle" id="icono"></i>
                    </div>
                    <div class="row justify-content-center pb-2">
                        <h5>{{adeudo.Nombre_Propietario}}</h5>
                    </div>
                    <div class="row justify-content-center">
                        <p><small>{{cve_cast}}</small></p>
                    </div>
                    <div class="row justify-content-center">
                        <p>RFC: <small>{{adeudo.RFC}}</small></p>
                    </div>
                    <div class="row justify-content-center">
                        <p>Domicilio: <small>{{adeudo.Ubicacion_Propiedad}}</small></p>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <ul>
                    <li>
                        Domicilio de ubicación: <strong>{{adeudo.Domicilio_Propiedad}}</strong>
                    </li>
                    <li>
                        Colonia de predio: <strong>{{adeudo.Colonia_Propiedad}} </strong>
                    </li>
                    <li>
                        Población: <strong>{{adeudo.Poblacion_Propiedad}}</strong>
                    </li>
                    <li>
                        Municipio: <strong>{{municipio}}</strong>
                    </li>
                </ul>
            </div>
            <div class="col-md-4">
                <ul>
                    <li>
                        Valor castastral: <strong>{{adeudo.Valor_Catastral | currency}}</strong>
                    </li>
                    <li>
                        Superficie de terreno: <strong>{{adeudo.Superficie_Terreno | number: '1.2-2'}}</strong>
                    </li>
                    <li>
                        Superficie de construccion: <strong>{{adeudo.Superficie_Construccion | number:
                            '1.2-2'}}</strong>
                    </li>
                    <li>
                        Subsidio: <strong>{{adeudo.Tipo_Subsidio}}</strong>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div *ngIf="adeudo.Total != 0" class="container d-none d-sm-block" style="margin-top: 1rem;">
        <table class="table">
            <thead>
                <tr class="table-success">
                    <th class="text-center" scope="col">Periodo</th>
                    <th class="text-center" scope="col">Principal</th>
                    <th class="text-center" scope="col">A.C.O</th>
                    <th class="text-center" scope="col">Asistencia Social</th>
                    <th class="text-center" scope="col">Subisidio</th>
                    <th class="text-center" scope="col">Pronto Pago</th>
                    <th class="text-center" scope="col">Recargo</th>
                    <th class="text-center" scope="col">Multa</th>
                    <th class="text-center" scope="col">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let detalle of this.adeudo.Detalle; let indice=index;"
                    [ngClass]="{'table-secondary': !detalle.check}">
                    <th>
                        <div class="ml-3">
                            <input [disabled]="indice==0 || recalculando" (click)="CambioCheck(detalle.check, indice)"
                                type="checkbox" checked="detalle.check" [(ngModel)]="detalle.check"
                                class="form-check-input" id="{{ detalle.anio }} - {{ detalle.trimestre }}">
                            <label for="{{ detalle.anio }} - {{ detalle.trimestre }}">{{ detalle.anio }} - {{detalle.trimestre}}</label>
                        </div>
                    </th>
                    <td class="text-right">{{ detalle.principal | currency }}</td>
                    <td class="text-right">{{ detalle.actualizacion | currency }}</td>
                    <td class="text-right">{{ detalle.asistencia_social | currency }}</td>
                    <td class="text-right">{{ detalle.descuento | currency }}</td>
                    <td class="text-right">{{ detalle.prontopago | currency }}</td>
                    <td class="text-right">{{ detalle.recargos | currency }}</td>
                    <td class="text-right">{{ detalle.multas | currency }}</td>
                    <td class="text-right">{{ detalle.total | currency }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="container d-block d-sm-none">
        <div *ngFor="let detalle of this.adeudo.Detalle; let indice=index;" class="row justify-content-center p-2">
            <table class="table table-bordered m-0" [ngClass]="{'table-secondary': !detalle.check}">
                <thead class="table-success" [ngClass]="{'deshabilitado': !detalle.check}">
                    <tr>
                        <th class="p-1">
                            <div class="ml-4">
                                <input [disabled]="indice==0" (click)="CambioCheck(detalle.check, indice)"
                                    type="checkbox" checked="detalle.check" [(ngModel)]="detalle.check"
                                    class="form-check-input" id="{{ detalle.anio }} - {{ detalle.trimestre }}">
                                <label class="m-0" for="{{ detalle.anio }} - {{ detalle.trimestre }}">Periodo:</label>
                            </div>

                        </th>
                        <th class="p-1 text-right">
                            {{ detalle.anio }} - {{ detalle.trimestre }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th class="p-1">
                            Principal:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.principal | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            A.C.O:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.actualizacion | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Asistencia Social:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.asistencia_social | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Subisidio:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.descuento | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Pronto Pago:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.prontopago | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Recargo:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.recargos | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Multa:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.multas | currency }}
                        </td>
                    </tr>
                    <tr>
                        <th class="p-1">
                            Total:
                        </th>
                        <td class="p-1 text-right">
                            {{ detalle.total | currency }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div *ngIf="this.adeudo.Cve_Catastral == null" class="container d-none d-sm-block" style="margin-top: 1rem;">
        <h1 class="text-center p-5">No existe esta clave catastral.</h1>
    </div>
    <div *ngIf="adeudo.Total == 0 && this.adeudo.Cve_Catastral != null && (adeudo.StatusPredio == 'ACTIVO' || adeudo.StatusPredio == 'SIN DEMANDA')"
        class="container d-none d-sm-block" style="margin-top: 1rem;">
        <h1 class="text-center p-5">La clave catastral no presenta adeudo.</h1>
    </div>
    <div *ngIf="adeudo.StatusPredio == 'DEMANDA'" class="container d-none d-sm-block" style="margin-top: 1rem;">
        <h1 class="text-center p-5">La clave catastral no se puede pagar en linea.</h1>
    </div>
    <div *ngIf="recalculando" class="container">
        <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar"
                aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>
        <p class="text-right">Recalculando Información...</p>
    </div>
    <div *ngIf="adeudo.Total != 0" class="container">
        <div class="row justify-content-end">
            <div class="col-md-5 p-2">
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                            <th class="text-right">
                                <label for="">Honorarios de Notificación</label>
                            </th>
                            <td class="text-right">
                                {{this.adeudo.Honorarios_Notificacion | currency}}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-right">
                                <label for="">Gastos Extraordinarios de Cobranza</label>
                            </th>
                            <td class="text-right">
                                {{this.adeudo.GastosExtCobranza | currency}}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-right">
                                <label for="">Redondeo</label>
                            </th>
                            <td class="text-right">
                                {{this.adeudo.Redondeo | currency}}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-right">
                                <label for="">Total</label>
                            </th>
                            <td class="text-right">
                                {{this.adeudo.Total | currency}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <div class="container pb-5 pt-4">
        <div class="row justify-content-center">
            <div class="col-md-4 pb-3 pb-md-0">
                <button class="btn btn-info btn-block btn-lg btnblock" (click)="Regresar()" [disabled]="recalculando"><i
                        class="fas fa-search mr-2"></i>Consultar Otra Clave</button>
            </div>
            <div class="col-md-4 pb-3 pb-md-0">
                <!-- <button class="btn btn-info btn-block btn-lg btnblock" (click)="Preelaborado()" [disabled]="recalculando || adeudo.Total == 0"><i class="fas fa-file-invoice-dollar mr-2"></i>Pagar en Banco</button> -->
            </div>
            <div class="col-md-4">
                <form #formulario action="https://www.adquiramexico.com.mx:443/mExpress/pago/avanzado" method="POST">
                    <input type="hidden" name="importe" value="{{this.adeudo.Total}}" />
                    <input type="hidden" name="referencia" value="{{this.referencia_pago}}" />
                    <input type="hidden" name="urlretorno" value="{{url_api}}PagosPredial/PostAcreditacionPago" />
                    <input type="hidden" name="idexpress" value="{{id_express}}" />
                    <input type="hidden" name="financiamiento" value="0" />
                    <input type="hidden" name="plazos" value="" />
                    <input type="hidden" name="mediospago" value="100000" />
                    <input type="hidden" name="signature" value="{{this.generated_signature}}" />
                    <button (click)="ValidarSubmit()" class="btn btn-info btn-block btn-lg btnblock"
                        [disabled]="recalculando || adeudo.Total == 0"><i class="far fa-credit-card mr-2"></i>Pagar en
                        Linea</button>
                </form>
            </div>
        </div>
    </div>

    <div class="container" [hidden]="form_tarjeta">
        <div class="card">
            <!-- <form method="POST" #formulariobanorte action="https://via.pagosbanorte.com/payw2"> -->
            <form method="POST" #formulariobanorte action="https://via.banorte.com/secure3d/Solucion3DSecure.htm">
                <!-- <input type="hidden" name="ID_AFILIACION" value="{{this.prepago_banorte.id_afiliacion}}" />
                <input type="hidden" name="USUARIO" value="{{this.prepago_banorte.usuario}}" />
                <input type="hidden" name="CLAVE_USR" value="{{this.prepago_banorte.clave_usr}}" />
                <input type="hidden" name="CMD_TRANS" value="{{this.prepago_banorte.cmd_trans}}" />
                <input type="hidden" name="MONTO" value="{{this.prepago_banorte.monto}}" />
                <input type="hidden" name="MODO" value="{{this.prepago_banorte.modo}}" />
                <input type="hidden" name="NUMERO_CONTROL" value="{{this.prepago_banorte.numero_control}}" />
                <input type="hidden" name="NUMERO_TARJETA" value="{{this.prepago_banorte.numero_tarjeta}}" />
                <input type="hidden" name="FECHA_EXP" value="{{this.prepago_banorte.fecha_exp}}" />
                <input type="hidden" name="CODIGO_SEGURIDAD" value="{{this.prepago_banorte.codigo_seguridad}}" />
                <input type="hidden" name="MODO_ENTRADA" value="{{this.prepago_banorte.modo_entrada}}" />
                <input type="hidden" name="ESTATUS_3D" value="{{this.prepago_banorte.estatus}}" /> -->

                <input type="hidden" name="NUMERO_TARJETA" value="{{this.prepago_banorte.numero_tarjeta}}" />
                <input type="hidden" name="FECHA_EXP" value="{{this.prepago_banorte.fecha_exp}}" />
                <input type="hidden" name="MONTO" value="{{this.prepago_banorte.monto}}" />
                <input type="hidden" name="MARCA_TARJETA" value="{{this.prepago_banorte.marca_tarjeta}}" />
                <input type="hidden" name="ID_AFILIACION" value="{{this.prepago_banorte.id_afiliacion}}" />
                <input type="hidden" name="NOMBRE_COMERCIO" value="{{this.prepago_banorte.nombre_comercio}}" />
                <input type="hidden" name="CIUDAD_COMERCIO" value="{{this.prepago_banorte.ciudad_comercio}}" />
                <input type="hidden" name="URL_RESPUESTA" value="{{this.prepago_banorte.url_respuesta}}" />
                <input type="hidden" name="CERTIFICACION_3D" value="{{this.prepago_banorte.certificacion3d}}" />
                <input type="hidden" name="REFERENCIA3D" value="{{this.prepago_banorte.referencia3d}}" />
                <input type="hidden" name="CIUDAD" value="{{this.prepago_banorte.ciudad}}" />
                <input type="hidden" name="PAIS" value="{{this.prepago_banorte.pais}}" />
                <input type="hidden" name="CORREO" value="{{this.prepago_banorte.correo}}" />
                <input type="hidden" name="NOMBRE" value="{{this.prepago_banorte.nombre}}" />
                <input type="hidden" name="APELLIDO" value="{{this.prepago_banorte.apellido}}" />
                <input type="hidden" name="CODIGO_POSTAL" value="{{this.prepago_banorte.codigo_postal}}" />
                <input type="hidden" name="ESTADO" value="{{this.prepago_banorte.estado}}" />
                <input type="hidden" name="CALLE" value="{{this.prepago_banorte.calle}}" />
                <input type="hidden" name="VERSION_3D" value="{{this.prepago_banorte.version3d}}" />
                <input type="hidden" name="NUMERO_CELULAR" value="{{this.prepago_banorte.numero_celular}}" />
                <input type="hidden" name="TIPO_TARJETA" value="{{this.prepago_banorte.tipo_tarjeta}}" />

                <!-- <button class="proceed"><svg class="sendicon" width="24" height="24" viewBox="0 0 24 24"
                        (click)="ValidarSubmitBanorte()">
                        <path d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"></path>
                    </svg></button> -->
                <!-- <img src="https://seeklogo.com/images/V/VISA-logo-62D5B26FE1-seeklogo.com.png" class="logo-card"> -->
                <img src="../../../../../assets/MasterCard_logo.png" alt="" style="height: 50px; width: 80px;" *ngIf="visa_mastercard == 'mastercard'">
                <img src="../../../../../assets/pngwing.com (1).png" alt="" style="height: 60px; width: 80px;" *ngIf="visa_mastercard == 'visa'">
                <label>Numero de Tarjeta:</label>
                <input id="user" type="text" class="input cardnumber" [disabled]="true"
                    placeholder="1234 5678 9101 1121" [(ngModel)]="prepago_tarjeta.numero_tarjeta"
                    [ngModelOptions]="{standalone: true}">
                <label>Nombre:</label>
                <input class="input name" [(ngModel)]="prepago_tarjeta.nombre " [disabled]="true"
                    [ngModelOptions]="{standalone: true}" placeholder="">
                <input class="input toleft ccv" [(ngModel)]="prepago_tarjeta.mes" [disabled]="true"
                    [ngModelOptions]="{standalone: true}" placeholder="MM">
                <input class="input toleft ccv" [(ngModel)]="prepago_tarjeta.anio" [disabled]="true"
                    [ngModelOptions]="{standalone: true}" placeholder="YY">
                <input class="input toleft ccv" [disabled]="true" [(ngModel)]="prepago_tarjeta.codigo_seguridad"
                    [ngModelOptions]="{standalone: true}" placeholder="CVV">
            </form>
        </div>
        <br>
        <div class="row gx-3" style="border: black 1px solid; border-radius: 2%;">
            <div class="col-6">
                <div class="d-flex flex-column">
                    <p class="text mb-1">Correo</p>
                    <input class="form-control mb-3" type="text" placeholder="Correo" [(ngModel)]="prepago_tarjeta.correo" [ngModelOptions]="{standalone: true}" (change)="CambioCorreo($event)">
                </div>
            </div>
            <div class="col-6">
                <div class="d-flex flex-column">
                    <p class="text mb-1">Celular</p>
                    <input class="form-control mb-3" type="text" placeholder="Celular" [(ngModel)]="prepago_tarjeta.celular" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="col-6">
                <div class="d-flex flex-column">
                    <p class="text mb-1">Nombre</p>
                    <input class="form-control mb-3" type="text" placeholder="Nombre" [(ngModel)]="prepago_tarjeta.nombre" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="col-6">
                <div class="d-flex flex-column">
                    <p class="text mb-1">Primer Apellido</p>
                    <input class="form-control mb-3" type="text" placeholder="Apellido" [(ngModel)]="prepago_tarjeta.primer_apellido" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="col-5">
                <div class="d-flex flex-column">
                    <p class="text mb-1">Estado</p>
                    <select name="select" class="form-control"  [(ngModel)]="prepago_tarjeta.estado" [ngModelOptions]="{standalone: true}">
                        <option value="SI">Sinaloa</option>
                        <option value="SO">Sonora</option>
                      </select>
                </div>
            </div>
            <div class="col-5">
                <div class="d-flex flex-column">
                    <p class="text mb-1">Ciudad</p>
                    <input class="form-control mb-3" type="text" placeholder="Ciudad" [(ngModel)]="prepago_tarjeta.ciudad" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="col-2">
                <div class="d-flex flex-column">
                    <p class="text mb-1">CP</p>
                    <input class="form-control mb-3" type="text" placeholder="CP" [(ngModel)]="prepago_tarjeta.codigo_postal" [ngModelOptions]="{standalone: true}">
                </div>
            </div>     
            <div class="col-12">
                <div class="d-flex flex-column">
                    <p class="text mb-1">Calle</p>
                    <input class="form-control mb-3" type="text" placeholder="Calle" [(ngModel)]="prepago_tarjeta.calle" [ngModelOptions]="{standalone: true}">
                </div>
            </div>

            <div class="col-6">
                <div class="d-flex flex-column">
                    <p class="text mb-1">Número de Tarjeta</p>
                    <input class="form-control mb-3" type="number" (change)="CambiaTarjeta($event)" id="numero" [(ngModel)]="prepago_tarjeta.numero_tarjeta" placeholder="1234 5678 435678" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="col-2">
                <div class="d-flex flex-column">
                    <p class="text mb-1">MM</p>
                    <input class="form-control mb-3" type="text" placeholder="MM" [(ngModel)]="prepago_tarjeta.mes" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="col-2">
                <div class="d-flex flex-column">
                    <p class="text mb-1">YYYY</p>
                    <input class="form-control mb-3" type="text" placeholder="YYYY" [(ngModel)]="prepago_tarjeta.anio" [ngModelOptions]="{standalone: true}">
                </div>
            </div>
            <div class="col-2">
                <div class="d-flex flex-column">
                    <p class="text mb-1">CVV/CVC</p>
                    <input class="form-control mb-3 pt-2 " type="password" placeholder="***" [(ngModel)]="prepago_tarjeta.cvv" [ngModelOptions]="{standalone: true}">
                </div>
            </div>


            <div class="col-12"  (click)="ValidarSubmitBanorte()">
                <div class="btn btn-primary mb-3">
                    <span class="ps-3">Pagar</span>
                    <span class="fas fa-arrow-right"></span>
                </div>
            </div>
        </div>
    </div>
    <br>
</div>