import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class PadronMercadoAdeudoDetalle {
  
  id_cuenta: string;
  descripcion: string;
  periodo: string;
  importe: number;

 }
