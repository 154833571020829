import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss']
})
export class InicioComponent implements OnInit {

  constructor() { }

  ente = "";
  administracion = "";
  url_escudo = "";
  url_administracion = "";
  url_footer = "";
  direcciones = [];
  telefonos = [];
  emails = [];
  url_aviso = "";

  ngOnInit(): void {
    this.ente = environment.ente;
    this.administracion = environment.administracion;
    this.url_escudo = environment.url_escudo;
    this.url_administracion = environment.url_administracion;
    this.url_footer = environment.url_footer;
    this.direcciones = environment.direcciones;
    this.telefonos = environment.telefonos;
    this.emails = environment.emails;
    this.url_aviso = environment.url_aviso;
  }

}
