<div class="page-wrapper">
    <div class="header-top">
        <div class="auto-container clearfix">
            <div class="logo-outer">
                <div class="logo">
                    <a href="">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/3/30/Escudooficial2.png" alt=""
                            style="margin-top:10 px; margin-bottom: 10px; height: 80px;">
                    </a>
                </div>
            </div>
            <div style="float: right;">
                <div class="logo">
                    <a href="">
                        <img src="https://www.kindpng.com/picc/m/460-4601862_logo-puro-sinaloa-calidad-hd-png-download.png"
                            alt="" style="margin-top:5px;  height: 100px;">
                    </a>>
                </div>
            </div>
            <div class="text-center">
                <h1 class="d-none d-sm-block" style="padding-top:26px">H. Ayuntamiento de Guasave</h1>
            </div>
            <section class="consult-section" style="background:rgb(216, 216, 216) none repeat scroll 0% 0%;">
                <div class="auto-container">
                    <div class="sec-title-2 light centered">
                        <div class="title">
                            Predial Urbano
                        </div>
                        <h2>
                            CONSULTA CLAVE CASTASTRAL
                        </h2>
                        <div class="text">Ahorra tiempo, evita desplazamientos</div>
                    </div>
                    <div class="consult-form">
                        <form action="">
                            <div class="row clearfix">
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input [(ngModel)]="municipio" id="mun" type="text" tabindex="1" name="mun" placeholder="MUN"
                                        disabled="" value="011" maxlength="3" (keyup)="onKey($event, 'mun')">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input [(ngModel)]="poblacion" id="pob" type="text" tabindex="2" name="pob" placeholder="POB"
                                        maxlength="3" (keyup)="onKey($event, 'pob')">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input [(ngModel)]="cuadrante" id="cua" type="text" tabindex="3" name="cua" placeholder="CUA"
                                        maxlength="3" (keyup)="onKey($event, 'cua')">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input [(ngModel)]="manzana" id="man" type="text" tabindex="4" name="man" placeholder="MAN"
                                        maxlength="3" (keyup)="onKey($event, 'man')">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input [(ngModel)]="colonia" id="col" type="text" tabindex="5" name="col" placeholder="COL"
                                        maxlength="3" (keyup)="onKey($event, 'col')">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input [(ngModel)]="unidad" id="uni" type="text" tabindex="6" name="uni" placeholder="UNI"
                                        maxlength="3" (keyup)="onKey($event, 'uni')">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <section>
                <div class="auto-container">
                    <div class="row">
                        <div class="col-sm-4 col-md-4 mb-4 services-block-three">
                            <div>
                                <div class="inner-box text-center">
                                    <div class="icon-box">
                                        <button type="button">
                                            <img src="https://pagoselota.com.mx/img/iconoprocesopago.png" alt="" title="" style="width: 120px;">
                                        </button>
                                    </div>
                                    <div class="text text-center">¿Cómo realizo un pago en línea?</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 mb-4 services-block-three">
                            <div>
                                <div class="inner-box text-center">
                                    <div class="icon-box">
                                        <button>
                                            <img src="https://pagoselota.com.mx/img/iconoclavecatastral.png" alt="" title="" style="width: 120px;">
                                        </button>
                                    </div>
                                    <div class="text text-center">Ubica tu clave catastral</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-4 mb-4 services-block-three">
                            <div>
                                <div class="inner-box text-center">
                                    <div class="icon-box">
                                        <button type="button">
                                            <img src="https://pagoselota.com.mx/img/iconoclavecatastral.png" alt="" title="" style="width: 120px;">
                                        </button>
                                    </div>
                                    <div class="text text-center">Consulta tu adeudo</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer class="main-footer">
                <div class="auto-container">
                    <div class="widgets-section">
                        <div class="row clearfix">
                            <div class="big-column col-lg-6 col-md-12 col-sm-12">
                                <div class="row clearfix">
                                    <div class="footer-column col-lg-6 col-md-6 col-sm-12 col-6">
                                        <div class="footer-widget about-widget">
                                            <div class="logo-outer">
                                                <div class="logo">
                                                    <a href="/">
                                                        <img src="https://pagoselota.com.mx/img/logosolo.png" alt="" title="">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer-column col-md-6 col-sm-12">
                                        <div class="footer-widget links-widget">
                                            <h2>Dirección:</h2>
                                            <ul class="footer-link-list">
                                                <li>Ave. Gabriel Leyva y Lic. Saúl Aguilar Pico,</li>
                                                <li>C.P. 82700</li>
                                                <li>La Cruz, Elota, Sinaloa</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="big-column col-lg-6 col-md-12 col-sm-12">
                                <div class="row clearfix">
                                    <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div class="footer-widget links-widget">
                                            <h2>Números Telefónicos</h2>
                                            <ul class="footer-link-list">
                                                <li>01 696 96 1 01 11 </li>
                                                <li>01 696 96 1 02 42</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div class="footer-widget services-widget">
                                            <h2>Escríbenos a</h2>
                                            <ul class="service-list">
                                                <li>micompromisoeselota@elota.gob.mx</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer-bottom">
                        <div class="clearfix">
                            <div class="pull-left">
                                <div class="copyright">
                                    <span>H. Ayuntamiento de Elota 2018 - 2021</span>
                                </div>
                            </div>
                            <div class="pull-right">
                                <ul class="footer-list">
                                    <li>
                                        <a href="http://elota.gob.mx/cms/informacion-historica" target="_blank" rel="noopener noreferrer">Aviso de Privacidad</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>
</div>