import { Injectable } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { RegistrosConsulta } from '../Entidades/registros_consultas';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RegistrosConsultasService {

  registro_consulta_doc: AngularFirestoreDocument<RegistrosConsulta>;
  registro_consulta_coleccion: AngularFirestoreCollection<RegistrosConsulta>;
  registro_consulta: Observable<RegistrosConsulta[]>;

  constructor(private bd: AngularFirestore) { }

  addRegsitro(registro_consulta: RegistrosConsulta) {
    this.registro_consulta_coleccion = this.bd.collection("RegistrosConsultas");
    this.registro_consulta_coleccion.add(registro_consulta);
  }

  getRegistrosConsultas() {
    this.registro_consulta_coleccion = this.bd.collection("RegistrosConsultas");
    this.registro_consulta = this.registro_consulta_coleccion.snapshotChanges().pipe(map(action => {
      return action.map(a => {
        const data = a.payload.doc.data() as RegistrosConsulta;
        data.id = a.payload.doc.id;
        return data;
      })
    }))
    return this.registro_consulta;
  }
}
