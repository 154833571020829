import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-consulta-registros',
  templateUrl: './consulta-registros.component.html',
  styleUrls: ['./consulta-registros.component.scss']
})
export class ConsultaRegistrosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
