<div class="container justify-content-center mb-4">
    <div class="card col-12 justify-content-center">
        <div id="Recibo">
            <div>
                <div class="mb-3 mt-3" id="linea" style="background-color: #df0209;"></div>
                <div class="row align-items-center">
                    <div id="divimagen" class=" col-md-2 col-3 order-md-first text-center" style="padding-right: 0px;">
                        <img id="logoayuntamiento" src="{{url_escudo}}" alt="">
                    </div>
                    <div class="col-md-8 col-8 text-center">

                        <div class="text-center">
                            <h5 style="font-family: sans-serif;">{{this.ente}}</h5>
                            <h5 style="font-family: sans-serif;">TESORERIA MUNICIPAL - DIRECCIÓN DE INGRESOS</h5>
                            <Small style="font-family: sans-serif;">{{this.direcciones}}</Small>
                            <br>
                        </div>
                    </div>
                    <div class="col-md-2 col-2 order-md-last text-center" style="padding-left: 0px;">
                        <img id="logoadministracion" src="{{url_administracion}}" alt="">
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <h5 style="font-family: serif;">PREDIAL URBANO</h5>
                    </div>
                    <div>
                        <div class="row pt-2">
                            <div class="ml-5 col-5" id="datos">
                                <h6><strong>Clave Catastral:</strong> {{this.cve_cast}}</h6>
                                <h6><strong>Nombre:</strong> {{this.nombre}}</h6>
                                <h6><strong>Domicilio Ubicacion:</strong> {{this.domicilio_ubicacion}}</h6>
                                <h6><strong>Colonia:</strong> {{this.colonia}} <strong class="ml-2">CP:</strong>
                                    {{this.cp}} </h6>
                                <h6><strong>Domicilio Notificación:</strong> {{this.domicilio_notificacion}}</h6>
                            </div>
                            <div class="mr-5 col-5 align-items-end">
                                <h6><strong>Recibo Ofical:</strong> {{this.recibo_oficial}} <strong class="ml-2">No. de
                                        Operación:</strong> {{this.no_operacion}} </h6>
                                <h6><strong>Fecha y Hora:</strong> {{this.fecha_hora}} </h6>
                                <h6><strong>Oficina:</strong> {{this.oficina}}</h6>
                                <h6><strong>Caja:</strong> {{this.caja}} <strong class="ml-2">Usuario:</strong>
                                    {{this.usuario}} </h6>
                                <h6><strong>Origen:</strong> {{this.origen}} </h6>
                            </div>
                        </div>
                        <div class="row pt-2">
                            <div class="ml-5 col-3">
                                <h6><strong>Uso:</strong> {{this.uso}}</h6>
                            </div>
                            <div class="ml-5 col-3">
                                <h6><strong>Subsidio:</strong> {{this.subsidio}}</h6>
                            </div>
                            <div class="ml-5 col-4">
                                <h6><strong>Periodos de pago:</strong> {{this.periodo_pago_desde}} hasta
                                    {{this.periodo_pago_hasta}}</h6>
                            </div>
                        </div>
                        <div class="row">
                            <div class="ml-5 col-3">
                                <h6><strong>Sup. Terreno:</strong> {{this.sup_terreno}}</h6>
                            </div>
                            <div class="ml-5 col-3">
                                <h6><strong>Sup. Constr:</strong> {{this.sup_construccion}}</h6>
                            </div>
                            <div class="ml-5 col-4">
                                <h6><strong>Valor Catastral:</strong> {{this.valor_catastral | currency}}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="text-center pt-2">
                        <h3 style="font-family: serif;">Resumen de pago</h3>
                    </div>
                    <div id="tabla">
                        <div class="row pt-2">
                            <table class="table table-hover">
                                <thead class="thead-dark">
                                    <tr class="text-center">
                                        <th colspan="0">CUENTA CONTABLE</th>
                                        <th>CONCEPTO</th>
                                        <th>IMPORTE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detalle of this.recibo_data.Detalle_recibo">
                                        <td>{{detalle.id_cuenta}}</td>
                                        <td>{{detalle.descripcion}}</td>
                                        <td class="text-center">{{detalle.importe | currency}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>TOTAL DEL RECIBO</td>
                                        <td class="text-center">{{this.total_recibo | currency}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="">
                        <h5 style="font-family: serif;">Observaciones: </h5>
                        <p style="font-size: 13px;">
                            PAGO DE PREDIAL A PARTIR DEL PERIODO 0/0 HASTA 0/0 VALOR CATASTRAL: {{this.valor_catastral |
                            currency}} SUP. TERR:
                            ${{this.sup_terreno | currency}} SUP. CONST: {{this.sup_construccion | currency}} BASE
                            GRAVABLE: {{this.valor_catastral | currency}}
                            PAGO REALIZADO EN LINEA CON --FECHA DE PAGO {{this.fecha_pago | date:'dd-MM-yyy' }}
                            --REFERENCIA DE PAGO
                            {{this.referencia_pago}}
                        </p>
                        <p style="font-size: 13px;">
                            {{this.leyenda_fundamento}}
                        </p>
                        <div class="row align-items-center">
                            <div class="col-1">
                                <qrcode [qrdata]="QR" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
                            </div>
                            <div class="ml-5 col-10">
                                <h6>Cadena de seguridad: </h6>
                                <p style="font-size: 12px;">{{this.cadena_seguridad}}</p>
                            </div>
                        </div>
                         <div class="row pt-2">
                            <div class="col-12 text-center">
                                {{this.leyenda_recibo}}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>