<div class="container col-md-12 formulario">
    <div class="row p-0 m-0 align-items-center">
        <div class="col-6 d-flex justify-content-end">
            <img class="mr-2" id="escudo" src="{{this.url_administracion}}"
                alt="">
            <div class="text-left">
                <h5 class="p-2" style="color: white;">Sistema de Soporte</h5>
                <h5 class="p-2" style="color: white;">SADMUN</h5>
            </div>
        </div>
        <div class="col-6">
            <div class="row align-items-center justify-content-start">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header text-center">
                            Iniciar Sesión
                        </div>
                        <div class="card-body">
                            <div class="form-action">
                                <div class="form-row">
                                    <div class="form-group col-sm-12">
                                        <label for="">Usuario</label>
                                        <input [(ngModel)]="usuario.usuario" type="text" name="usuario"
                                            class="form-control form-control-sm"
                                            [ngClass]="{'is-invalid': validar && (this.usuario.usuario == null || this.usuario.usuario == '')}">
                                        <div class="invalid-feedback">
                                            *Debes introducir su usuario
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-sm-12">
                                        <label for="">Contraseña</label>
                                        <input [(ngModel)]="usuario.password" type="password" name="password"
                                            class="form-control form-control-sm"
                                            [ngClass]="{'is-invalid': validar && (this.usuario.password == null || this.usuario.password == '')}">
                                        <div class="invalid-feedback">
                                            *Debes introducir la contraseña
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row justify-content-center">
                                    <div class="form-group col-sm-6">
                                        <button id="btnLogin" (click)="Login()" class="btn btn-primary btn-block" style="margin-left: 10px;">Iniciar Sesión</button>
                                    </div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>