import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PrePagoenlinea } from '../Entidades/pre-pagoenlinea/pre-pagoenlinea.module';

@Injectable({
  providedIn: 'root'
})
export class PredialUrbanoService {

  constructor(private http: HttpClient) { }

  GetCalcula_PU(cve_catast: string, año_hasta: string, trim_hasta: string) {
    return this.http.post<any>(environment.url_webapi + 'PagosPredial/GetCalcula_PU', { cve_catast: cve_catast, ano_hasta: año_hasta, trim_hasta: trim_hasta });
  }

  PrePagoEnLinea(pre_pago: PrePagoenlinea) {
    console.log(environment.url_webapi + "PagosPredial/Pre_pagoenlinea", pre_pago)
    return this.http.post<any>(environment.url_webapi + "PagosPredial/PrePagoEnLinea", pre_pago);
  }

  PagoBanorte(prepago: any) {
    return this.http.post<any>("https://via.pagosbanorte.com/payw2", prepago);
  }

  GetRecibo_PU(recibo: string) {
    return this.http.post<any>(environment.url_webapi + "PagosPredial/GetRecibo_PU", { recibo: recibo });
  }


  PostGuardarPrepagoBanorte(prepago_banorte: any) {
    return this.http.post<any>(environment.url_webapi + "PagosPredial/WritePrepagoBanorte", { VA: 'bysystem', ...prepago_banorte });
  }
  PostGuardarPrepagoBanorteByCorreo(correo: any) {
    return this.http.post<any>(environment.url_webapi + "PagosPredial/ReadPrepagoBanorte", { VA: 'byid', correo: correo });
  }
}
