import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sergio',
  templateUrl: './sergio.component.html',
  styleUrls: ['./sergio.component.scss']
})
export class SergioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
