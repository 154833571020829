import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gabriel',
  templateUrl: './gabriel.component.html',
  styleUrls: ['./gabriel.component.scss']
})
export class GabrielComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
