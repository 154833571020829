import { ConsultaAdeudoPadronComponent } from './Componentes/Pagos/PadronMercados/consulta-adeudo-padron/consulta-adeudo-padron.component';
import { ReciboEnLineaComponent } from './Componentes/Pagos/Predial/recibo-en-linea/recibo-en-linea.component';
import { PreelaboradoComponent } from './Componentes/Pagos/Predial/preelaborado/preelaborado.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './Componentes/inicio/inicio.component';
import { AdeudoComponent } from './Componentes/Pagos/Predial/adeudo/adeudo.component';
import { ConsultaAdeudoComponent } from './Componentes/Pagos/Predial/consulta-adeudo/consulta-adeudo.component';
import { LoginComponent } from './Componentes/login/login.component';
import { ConsultaDePagosComponent } from './Componentes/Pagos/Predial/consulta-de-pagos/consulta-de-pagos.component';
import { AuthGuard } from './auth.guard';
import { AcreditacionPagoComponent } from './Componentes/Pagos/acreditacion-pago/acreditacion-pago.component';
import { AdeudoPadronComponent } from './Componentes/Pagos/PadronMercados/adeudo-padron/adeudo-padron.component';
import { ConsultaAdeudoPadronAlcoholesComponent } from './Componentes/Pagos/PadronAlcoholes/consulta-adeudo-padron-alcoholes/consulta-adeudo-padron-alcoholes.component';
import { AdeudoPadronAlcoholesComponent } from './Componentes/Pagos/PadronAlcoholes/adeudo-padron-alcoholes/adeudo-padron-alcoholes.component';
import { ErrorPagoComponent } from './Componentes/Pagos/Predial/error-pago/error-pago.component';


const routes: Routes = [
  {
    path: "",
    component: InicioComponent,
    children: [
      {
        path: '',
        component: ConsultaAdeudoComponent
      },
      {
        path: 'Adeudo/:cve_catast',
        component: AdeudoComponent,
        data: { cve_catast: "" }
      },
      {
        path: 'AcreditacionPago/:autorizacion/:referencia/:folio_recibo',
        component: AcreditacionPagoComponent,
        data: { cve_catast: "" }
      },
      {
        path: 'ErrorPago',
        component: ErrorPagoComponent
      },
      {
        path: 'AdeudoPadronMercado/:id_padron',
        component: AdeudoPadronComponent
      },
      {
        path:'ConsultaAdeudoPadron',
        component: ConsultaAdeudoPadronComponent
      },
      {
        path:'ConsultaAdeudoPadronAlcoholes',
        component: ConsultaAdeudoPadronAlcoholesComponent
      },
      {
        path: 'AdeudoPadronAlcoholes/:id_padron',
        component: AdeudoPadronAlcoholesComponent
      }
    ]
  },
  {
    path: 'Preelaborado',
    component: PreelaboradoComponent,
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'consultaPagos',
    component: ConsultaDePagosComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'ReciboLinea/:recibo/:referencia',
    component: ReciboEnLineaComponent,
    data: { recibo: "" }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
