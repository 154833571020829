import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-consulta-adeudo-padron-alcoholes',
  templateUrl: './consulta-adeudo-padron-alcoholes.component.html',
  styleUrls: ['./consulta-adeudo-padron-alcoholes.component.scss']
})
export class ConsultaAdeudoPadronAlcoholesComponent implements OnInit {

  id_padron: string;
  validar: boolean = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  ConsultarAdeudo(): void {
    if (this.Validar())
      this.router.navigate(["/AdeudoPadronAlcoholes/" + this.id_padron])
  }

  Validar() {
    this.validar = true;
    if (this.id_padron == ""|| isNullOrUndefined(this.id_padron)) return false;
    return true;
  }

}
