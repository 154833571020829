import { PadronMercadoAdeudoDetalle } from './../padron-mercado-adeudo-detalle/padron-mercado-adeudo-detalle.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class PadronMercadoAdeudo {

  descripcion: string;
  anio: number;
  importe: number;
  mostrar_detalle: boolean;
  detalle: PadronMercadoAdeudoDetalle[];

 }
