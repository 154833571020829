import { Component, OnInit } from '@angular/core';
import { isNumber } from 'util';

@Component({
  selector: 'app-miguel',
  templateUrl: './miguel.component.html',
  styleUrls: ['./miguel.component.scss']
})
export class MiguelComponent implements OnInit {

  constructor() { }

  municipio: string = "011";
  poblacion: string = "";
  cuadrante: string = "";
  manzana: string = "";
  colonia: string = "";
  unidad: string = "";
  cve_catastral: string;

  ngOnInit(): void {
  }

  onKey(event: KeyboardEvent, clave: string) {
    if (this.IsNumber(event)) {
      switch (clave) {
        case "mun":
          // if (this.municipio.length <= 3) {
          //   this.municipio += event.key;
          // }
          if (this.municipio.length == 3) {
            document.getElementById("pob").focus()
          }
          break;

        case "pob":
          // if (this.poblacion.length <= 3) {
          //   this.poblacion += event.key;
          // }
          if (this.poblacion.length == 3) {
            document.getElementById("cua").focus()
          }
          break;

        case "cua":
          // if (this.cuadrante.length <= 3) {
          //   this.cuadrante += event.key;
          // }
          if (this.cuadrante.length == 3) {
            document.getElementById("man").focus()
          }
          break;

        case "man":
          // if (this.manzana.length <= 3) {
          //   this.manzana += event.key;
          // }
          if (this.manzana.length == 3) {
            document.getElementById("col").focus()
          }
          break;

        case "col":
          // if (this.colonia.length <= 3) {
          //   this.colonia += event.key;
          // }
          if (this.colonia.length == 3) {
            document.getElementById("uni").focus()
          }
          break;
        case "uni":
          // if (this.unidad.length <= 3) {
          //   this.unidad += event.key;
          // }
          break;
        default:
          break;
      }
      
      this.cve_catastral = this.municipio + this.poblacion + this.cuadrante + this.manzana + this.colonia + this.unidad;
    }
  }

  IsNumber(value): boolean {
    if (value.charCode >= 48 && value.charCode <= 57 && (value.keyCode < 96 || value.keyCode > 105) && value.keyCode !==190  && value.keyCode !==110 && value.keyCode !==8 && value.keyCode !==9)
      return true
  }
}
