import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Usuarios } from '../Entidades/usuarios/usuarios.module';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  url = "";

  constructor(private http: HttpClient) {}

  CrearConexionMunicipio(){
    switch (localStorage.getItem("municipio")) {
      case "Guasave": {
        this.url = "http://187.216.118.210:8071/api/";
        //this.url = "http://localhost:63403/api/";
        break;
      }
      case "Ahome": {
        this.url = "";
        break;
      }
    }
  }

  GetValidarToken() {
    this.CrearConexionMunicipio();
    return this.http.get<any>(this.url + "Ingresos/ValidarToken");
  }

  PostLogin(Usuario: Usuarios) {
    this.CrearConexionMunicipio();
    return this.http.post<any>(this.url + "Login/PostLogin", Usuario);
  }

  ObtenerToken() {
    return localStorage.getItem('token');
  }
  ObtenerUsuario() {
    return localStorage.getItem('usuario');
  }
}
