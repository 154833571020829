import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-acreditacion-pago',
  templateUrl: './acreditacion-pago.component.html',
  styleUrls: ['./acreditacion-pago.component.scss']
})
export class AcreditacionPagoComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }

  no_autorizacion: string;
  referencia_pago: string;
  folio_recibo: string;

  municipio = "";
  ente = "";
  administracion = "";
  url_escudo = "";
  url_administracion = "";
  url_footer = "";
  direcciones = [];
  telefonos = [];
  emails = [];
  url_aviso = "";

  url_rpt = "";

  ngOnInit(): void {
    this.municipio = environment.municipio;
    this.ente = environment.ente;
    this.administracion = environment.administracion;
    this.url_escudo = environment.url_escudo;
    this.url_administracion = environment.url_administracion;
    this.url_footer = environment.url_footer;
    this.direcciones = environment.direcciones;
    this.telefonos = environment.telefonos;
    this.emails = environment.emails;
    this.url_aviso = environment.url_aviso;
    this.route.params.subscribe(params => {
      this.no_autorizacion = params.autorizacion;
      this.referencia_pago = params.referencia;
      this.folio_recibo = params.folio_recibo;
    });
    this.url_rpt = environment.url_rpt;
  }

  ImprimirRecibo() {
    window.open(this.url_rpt + "?recibo=" + this.folio_recibo, "_blank")
  }

  Regresar() {
    this.router.navigate([""]);
  }

}
