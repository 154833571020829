import { environment } from './../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuarios } from 'src/app/Entidades/usuarios/usuarios.module';
import { UsuariosService } from 'src/app/Sercivios/usuarios.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  usuario: Usuarios = {
    usuario: "",
    password: ""
  }
  validar: boolean = false;
  municipio: string = "Guasave";
  url_administracion: string;

  constructor(private usuarios_service: UsuariosService, private router: Router) { }

  ngOnInit(): void {
    this.url_administracion = environment.url_administracion;
  }

  Login() {
    try {
      localStorage.setItem("municipio", this.municipio);
      if (this.Validar()) {
        this.usuarios_service.PostLogin(this.usuario).subscribe(data => {
          if (!isNullOrUndefined(data)) {
            localStorage.setItem('token', data)
            localStorage.setItem("usuario", btoa(JSON.stringify(this.usuario)))
            this.router.navigate(["/consultaPagos"]);
          }
          else {
            // this.toastr.error("Datos Incorrectos");
          }
        }, (error: HttpErrorResponse) => {
          console.log(error);
          if (error.status == 0) {
            // this.toastr.error("No se pudo establecer comunicación con el servidor.");
          }
          if (error.status == 401) {
            // this.toastr.error(error.error);
          }
        })
      }
      else {
        // this.toastr.error("Faltan datos")
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  Validar(): boolean {
    this.validar = true;

    if (this.usuario.usuario == "" || isNullOrUndefined(this.usuario.usuario)) return false;
    if (this.usuario.password == "" || isNullOrUndefined(this.usuario.password)) return false;

    return true;
  }
}
