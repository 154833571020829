<div class="container">
    <div class="row justify-content-center p-2">
        <table class="table table-bordered m-0">
            <thead class="table-success">
                <tr>
                    <th class="p-1">
                        <div class="ml-4">
                            <!-- <input [disabled]="indice==0"
                                type="checkbox" checked="detalle.check" [(ngModel)]="detalle.check"
                                class="form-check-input" id="{{ detalle.anio }} - {{ detalle.trimestre }}"> -->
                            <label class="m-0">Periodo:</label>
                        </div>

                    </th>
                    <th class="p-1 text-right">
                        Primero
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th class="p-1">
                        Principal:
                    </th>
                    <td class="p-1 text-right">
                        Hoda
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>