import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { PredialUrbanoService } from 'src/app/Sercivios/predial-urbano.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { RegistrosConsultasService } from 'src/app/Sercivios/registros-consultas.service';
import { RegistrosConsulta } from 'src/app/Entidades/registros_consultas';
import { formatDate } from '@angular/common';
import { PrePagoenlinea } from 'src/app/Entidades/pre-pagoenlinea/pre-pagoenlinea.module';
import CryptoJS from 'crypto-js';
import { ThrowStmt } from '@angular/compiler';
import { HttpErrorResponse } from '@angular/common/http';


declare var $: any;

@Component({
  selector: 'app-adeudo',
  templateUrl: './adeudo.component.html',
  styleUrls: ['./adeudo.component.scss']
})
export class AdeudoComponent implements OnInit {

  @ViewChild('formulario') formulario;
  @ViewChild('formulariobanorte') formulariobanorte;

  constructor(
    private route: ActivatedRoute,
    private predialurbanoservice: PredialUrbanoService,
    private router: Router,
    private activated_router: ActivatedRoute,
    private registros_consulta: RegistrosConsultasService) { }

  recalculando = false;
  adeudo = null;
  municipio = "";
  referencia_pago = "";
  generated_signature = "";
  cve_cast = "";
  url_api = "";
  id_express = environment.id_express;
  llave = environment.llave;

  form_tarjeta = true;

  totalstring: string;

  prepago_banorte: any = {}
  visa_mastercard: any = ""

  prepago_tarjeta: any = {}

  consulta_registro: RegistrosConsulta = {
    id: "",
    Cve_Catastral: "",
    Nombre_Propietario: "",
    RFC: "",
    Domicilio_Propiedad: "",
    Poblacion_Propiedad: "",
    Ubicacion_Propiedad: "",
    Colonia_Propiedad: "",
    Tipo_Subsidio: "",
    Observaciones: "",
    Periodo: "",
    Periodos: [],
    Principales: [],
    ACOs: [],
    Asistencias_Sociales: [],
    Subcidios: [],
    Recargos: [],
    Multas: [],
    Totales: [],
    Superficie_Terreno: 0,
    Superficie_Construccion: 0,
    Valor_Terreno: 0,
    Valor_Construccion: 0,
    Valor_Catastral: 0,
    Honorarios: 0,
    GastosEjecRequerimiento: 0,
    GastosEjecEmbargo: 0,
    GastosExtCobranza: 0,
    Total: 0,
    Redondeo: 0,
    Fecha: "",
    Municipio: "",
    Cruz_Roja: 0
  };

  ngOnInit(): void {
    let año = new Date().getFullYear().toString();
    this.municipio = environment.municipio
    this.url_api = environment.url_webapi;
    this.route.params.subscribe(params => {
      this.cve_cast = params.cve_catast.match(/\d{3}(?=\d{2,3})|\d+/g).join("-")
      this.predialurbanoservice.GetCalcula_PU(params.cve_catast, año, "4").subscribe(data => {
        if (data.Total != 0) {
          console.log(data)
          data.Detalle.map(res => {
            res.check = true;
            res.pp = res.prontopago
          });
          this.adeudo = data;
          this.consulta_registro.Cve_Catastral = this.adeudo.Cve_Catastral;
          this.consulta_registro.Periodo = this.adeudo.Detalle[this.adeudo.Detalle.length - 1].anio + " - " + this.adeudo.Detalle[this.adeudo.Detalle.length - 1].trimestre;
          this.consulta_registro.Nombre_Propietario = this.adeudo.Nombre_Propietario;
          this.consulta_registro.Total = this.adeudo.Total;
          this.consulta_registro.Fecha = formatDate(new Date(), 'yyyy-MM-dd', 'en_US');
          this.consulta_registro.RFC = this.adeudo.RFC;
          this.consulta_registro.Ubicacion_Propiedad = this.adeudo.Ubicacion_Propiedad;
          this.consulta_registro.Domicilio_Propiedad = this.adeudo.Domicilio_Propiedad;
          this.consulta_registro.Colonia_Propiedad = this.adeudo.Colonia_Propiedad;
          this.consulta_registro.Municipio = this.municipio;
          this.consulta_registro.Poblacion_Propiedad = this.adeudo.Poblacion_Propiedad;
          this.consulta_registro.Valor_Catastral = this.adeudo.Valor_Catastral;
          this.consulta_registro.Superficie_Terreno = this.adeudo.Superficie_Terreno;
          this.consulta_registro.Superficie_Construccion = this.adeudo.Superficie_Construccion;
          this.consulta_registro.Tipo_Subsidio = this.adeudo.Tipo_Subsidio;

          for (var item of this.adeudo.Detalle) {
            this.consulta_registro.Periodos.push(item.anio + " - " + item.trimestre);
            this.consulta_registro.Principales.push(item.principal);
            this.consulta_registro.ACOs.push(item.actualizacion);
            this.consulta_registro.Asistencias_Sociales.push(item.asistencia_social);
            this.consulta_registro.Subcidios.push(item.descuento);
            this.consulta_registro.Recargos.push(item.recargos);
            this.consulta_registro.Multas.push(item.multas);
            this.consulta_registro.Totales.push(item.total)
          }

          this.consulta_registro.Honorarios = this.adeudo.Honorarios;
          this.consulta_registro.GastosEjecRequerimiento = this.adeudo.Honorarios;
          this.consulta_registro.GastosEjecEmbargo = this.adeudo.GastosEjecEmbargo;
          this.consulta_registro.GastosExtCobranza = this.adeudo.GastosExtCobranza;
          this.consulta_registro.Cruz_Roja = 0;
          this.consulta_registro.Total = this.adeudo.Total;
          this.totalstring = this.adeudo.Total.toString();

          this.referencia_pago = this.adeudo.Cve_Catastral.slice(3, 18) +
            this.adeudo.Desde +
            this.adeudo.Detalle[this.adeudo.Detalle.length - 1].anio +
            this.adeudo.Detalle[this.adeudo.Detalle.length - 1].trimestre;

          this.generated_signature = CryptoJS.HmacSHA256(this.referencia_pago + this.totalstring + this.id_express, this.llave).toString(CryptoJS.enc.Hex);

        } else {
          this.adeudo = data;
        }
      }, (error: HttpErrorResponse) => {
        alert("Error al conectar con el servidor.");
      });
    });
  }

  CambioCheck(valorcheck: boolean, indice: number) {
    let index = 0;
    if (valorcheck) {
      index = indice - 1;
      for (var i = indice; i < this.adeudo.Detalle.length; i++) {
        this.adeudo.Detalle[i].check = false
        this.adeudo.Detalle[i].total = this.adeudo.Detalle[i].total - this.adeudo.Detalle[i].prontopago
        this.adeudo.Detalle[i].prontopago = 0;
      }
    } else {
      index = indice;
      for (var i = indice; i >= 0; i--) {
        this.adeudo.Detalle[i].check = true
        if (i != 0) {
          this.adeudo.Detalle[i].prontopago = this.adeudo.Detalle[i].pp;
          this.adeudo.Detalle[i].total = this.adeudo.Detalle[i].total + this.adeudo.Detalle[i].prontopago
        }
      }
    }


    this.recalculando = true;
    this.predialurbanoservice.GetCalcula_PU(this.adeudo.Cve_Catastral, this.adeudo.Detalle[index].anio, this.adeudo.Detalle[index].trimestre).subscribe(data => {
      this.adeudo.Total = data.Total;
      this.adeudo.Redondeo = data.Redondeo;
      this.adeudo.Honorarios_Notificacion = data.Honorarios_Notificacion;
      this.adeudo.GastosEjecRequerimiento = data.GastosEjecRequerimiento;
      this.adeudo.GastosEjecEmbargo = data.GastosEjecEmbargo;
      this.adeudo.GastosExtCobranza = data.GastosExtCobranza;
      this.recalculando = false;

      this.totalstring = this.adeudo.Total.toString();

      this.referencia_pago = this.adeudo.Cve_Catastral.slice(3, 18) + this.adeudo.Detalle[0].anio +
        this.adeudo.Detalle[0].trimestre + this.adeudo.Detalle[index].anio + this.adeudo.Detalle[index].trimestre;

      this.generated_signature = CryptoJS.HmacSHA256(this.referencia_pago + this.totalstring + this.id_express, this.llave).toString(CryptoJS.enc.Hex);
    }, (error: HttpErrorResponse) => {
      alert("Error al conectar con el servidor.");
    });
  }

  Preelaborado() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        valor: JSON.stringify(this.adeudo)
      }
    }
    this.router.navigate(["Preelaborado"], navigationExtras);
  }

  Regresar() {
    this.router.navigate([""]);
  }

  ValidarSubmit() {
    // var x = 1;
    var anio_hasta;
    var trimestre_hasta;
    for (var item of this.adeudo.Detalle) {
      if (item.check) {
        anio_hasta = item.anio
        trimestre_hasta = item.trimestre
      }
    }

    var pre_pago: PrePagoenlinea = {
      id: null,
      referencia: this.referencia_pago,
      clave_catastral: this.adeudo.Cve_Catastral,
      desde: this.adeudo.Desde,
      hasta: anio_hasta + "" + trimestre_hasta,
      importe: this.adeudo.Total,
      redondeo: this.adeudo.Redondeo
    }
    if (environment.banorte != true) {
      this.predialurbanoservice.PrePagoEnLinea(pre_pago).subscribe(data => {
        this.formulario.nativeElement.submit()
      }, (error: HttpErrorResponse) => {
        alert(error.error)
      });
    } else {
      this.form_tarjeta = false;
    }
  }

  ValidarSubmitBanorte() {
    // var x = 1;

    var anio_hasta;
    var trimestre_hasta;
    for (var item of this.adeudo.Detalle) {
      if (item.check) {
        anio_hasta = item.anio
        trimestre_hasta = item.trimestre
      }
    }

    this.prepago_tarjeta.referencia = this.referencia_pago
    this.prepago_tarjeta.cve_catast = this.adeudo.Cve_Catastral
    this.prepago_tarjeta.desde = this.adeudo.Detalle[0].anio + " - " + this.adeudo.Detalle[0].trimestre
    this.prepago_tarjeta.hasta = anio_hasta + " - " + trimestre_hasta
    this.prepago_tarjeta.importe = this.adeudo.Total
    this.prepago_tarjeta.redondeo = this.adeudo.Redondeo



    this.prepago_banorte.id_afiliacion = "8224656";
    this.prepago_banorte.numero_tarjeta = this.prepago_tarjeta.numero_tarjeta;
    this.prepago_banorte.fecha_exp = this.prepago_tarjeta.mes + '/' + this.prepago_tarjeta.anio;
    this.prepago_banorte.monto = this.adeudo.Total;
    this.prepago_banorte.marca_tarjeta = "MC"; //validar
    this.prepago_banorte.id_afilacion = "8224656";
    this.prepago_banorte.nombre_comercio = "Municipio de Elota"; //Environment
    this.prepago_banorte.ciudad_comercio = "Elota"; //Municipio
    this.prepago_banorte.url_respuesta = environment.url_webapi + "PagosPredial/PostAcreditacionPagoBanorte";
    this.prepago_banorte.certificacion3d = "03";
    this.prepago_banorte.version3d = "2";
    this.prepago_banorte.tipo_tarjeta = "CR";
    this.prepago_banorte.referencia3d = "888888888888890"; //Formar Referencia
    this.prepago_banorte.pais = "MX";

    this.prepago_banorte.ciudad = this.prepago_tarjeta.ciudad;
    this.prepago_banorte.correo = this.prepago_tarjeta.correo;
    this.prepago_banorte.nombre = this.prepago_tarjeta.nombre;
    this.prepago_banorte.apellido = this.prepago_tarjeta.primer_apellido;
    this.prepago_banorte.codigo_postal = this.prepago_tarjeta.codigo_postal;
    this.prepago_banorte.estado = this.prepago_tarjeta.estado;
    this.prepago_banorte.calle = this.prepago_tarjeta.calle;
    this.prepago_banorte.numero_celular = this.prepago_tarjeta.celular;

    //Guardar prepago
    this.predialurbanoservice.PostGuardarPrepagoBanorte(this.prepago_tarjeta).subscribe(data => {
      setTimeout(() => {
        this.formulariobanorte.nativeElement.submit()
      }, 5000);
    })
    
    // return;

    // this.predialurbanoservice.PrePagoEnLinea(pre_pago).subscribe(data => {
    //   this.formulario.nativeElement.submit()
    // }, (error: HttpErrorResponse) => {
    //   alert(error.error)
    // });
  }

  CambioCorreo(event) {
    this.predialurbanoservice.PostGuardarPrepagoBanorteByCorreo(event.target.value).subscribe(data => {
      if (data.length != 0) {
        this.prepago_tarjeta = data[0];
      }
    })
  }

  CambiaTarjeta(e) {
    var cadena = (this.prepago_banorte.no_tarjeta).toString()
    if (cadena == 5) {
      this.visa_mastercard = "mastercard"
    } else if (cadena == 4) {
      this.visa_mastercard = "visa"
    }
  }

  PagarBanorte() {
    // this.prepago_banorte.id_afilacion = "8224656";
    // this.prepago_banorte.USUARIO = "a8224656";
    // this.prepago_banorte.CLAVE_USR = "R:5$ViVgV-9";
    // this.prepago_banorte.CMD_TRANS = "VENTA";
    // this.prepago_banorte.MONTO = this.adeudo.Total;
    // this.prepago_banorte.MODO = "AUT";
    // this.prepago_banorte.NUMERO_CONTROL = this.referencia_pago;
    // this.prepago_banorte.NUMERO_TARJETA = this.prepago_banorte.no_tarjeta;
    // this.prepago_banorte.FECHA_EXP = this.prepago_banorte.mes + '' + this.prepago_banorte.anio;
    // this.prepago_banorte.CODIGO_SEGURIDAD = this.prepago_banorte.codigo_seguridad;
    // this.prepago_banorte.MODO_ENTRADA = "MANUAL";
    // this.prepago_banorte.ESTATUS_3D = "200";

    // var prepago = {
    //   ID_AFILIACION: this.prepago_banorte.id_afilacion,
    //   USUARIO: this.prepago_banorte.USUARIO,
    //   CLAVE_USR: this.prepago_banorte.CLAVE_USR,
    //   CMD_TRANS: this.prepago_banorte.CMD_TRANS,
    //   MONTO: this.prepago_banorte.MONTO,
    //   MODO: this.prepago_banorte.MODO,
    //   NUMERO_CONTROL: this.prepago_banorte.NUMERO_CONTROL,
    //   NUMERO_TARJETA: this.prepago_banorte.NUMERO_TARJETA,
    //   FECHA_EXP: this.prepago_banorte.FECHA_EXP,
    //   CODIGO_SEGURIDAD: this.prepago_banorte.CODIGO_SEGURIDAD,
    //   MODO_ENTRADA: this.prepago_banorte.MODO_ENTRADA,
    //   ESTATUS_3D: this.prepago_banorte.ESTATUS_3D,
    // }

    this.prepago_banorte.NUMERO_TARJETA = this.prepago_banorte.no_tarjeta;
    this.prepago_banorte.FECHA_EXP = this.prepago_banorte.mes + '/' + this.prepago_banorte.anio;
    this.prepago_banorte.MONTO = this.adeudo.Total;
    this.prepago_banorte.marca_tarjeta = "MC";
    this.prepago_banorte.id_afilacion = "8224656";
    this.prepago_banorte.nombre_comercio = "Municipio de Elota";
    this.prepago_banorte.cuidad_comercio = "Elota";
    this.prepago_banorte.url_respuesta = environment.url_webapi + "PagosPredial/PostAcreditacionPago";
    this.prepago_banorte.certificacion3d = "03";
    this.prepago_banorte.referencia3d = this.referencia_pago;
    this.prepago_banorte.cuidad = "Elota";
    this.prepago_banorte.pais = "MX";
    this.prepago_banorte.correo = "sergio_0151@hotmail.com";
    this.prepago_banorte.nombre = "SERGIO";
    this.prepago_banorte.apellido = "GASTELUM";
    this.prepago_banorte.codigo_postal = "81040";
    this.prepago_banorte.estado = "SI";
    this.prepago_banorte.version3d = "8224656";
    this.prepago_banorte.numero_celular = "8224656";
    this.prepago_banorte.tipo_tarjeta = "8224656";

    // var prepago = {
    //   NUMERO_TARJETA: this.prepago_banorte.NUMERO_TARJETA,
    //   FECHA_EXP: this.prepago_banorte.FECHA_EXP,
    //   MONTO: this.prepago_banorte.MONTO,
    //   MARCA_TARJETA: this.prepago_banorte.marca_tarjeta
    //   this.prepago_banorte.id_afilacion
    //   this.prepago_banorte.nombre_comercio
    //   this.prepago_banorte.cuidad_comercio
    //   this.prepago_banorte.url_respuesta
    //   this.prepago_banorte.certificacion3d
    //   this.prepago_banorte.referencia3d
    //   this.prepago_banorte.cuidad
    //   this.prepago_banorte.pais
    //   this.prepago_banorte.correo
    //   this.prepago_banorte.nombre
    //   this.prepago_banorte.apellido
    //   this.prepago_banorte.codigo_postal
    //   this.prepago_banorte.estado
    //   this.prepago_banorte.version3d
    //   this.prepago_banorte.numero_celular
    //   this.prepago_banorte.tipo_tarjeta
    // }

    // this.predialurbanoservice.PagoBanorte(prepago).subscribe(data => {
    //   console.log(data);
    // }, (error: HttpErrorResponse) => {
    //   console.log(error)
    // });
  }
}
