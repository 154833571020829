<div id="app" class="page-wrapper">
    <header class="main-header">
        <div class="header-top">
        </div>
        <div class="header-upper">
            <div class="inner-container">
                <div class="auto-container clearfix">
                    <div class="logo-outer">
                        <div class="logo">
                            <a href="">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/3/30/Escudooficial2.png" alt=""
                                    style="margin-top:5px; height: 100px;">
                            </a>
                        </div>
                    </div>
                    <div style="float: right;">
                        <div class="logo">
                            <a href="">
                                <img src="https://cecytesinaloa.edu.mx/web/wp-content/uploads/2018/09/Logotipo-PuroSinaloa.png"
                                    alt="" style="margin-top:5px;  height: 100px;">
                            </a>
                        </div>
                    </div>
                    <div class="text-center">
                        <h1 class="d-none d-sm-block" style="padding-top:26px">H. Ayuntamiento de Guasave</h1>
                    </div>
                </div>
            </div>
            <section class="consult-section" style="background:rgb(216, 216, 216) none repeat scroll 0% 0%;">
                <div class="auto-container">
                    <div class="sec-title-2 light centered">
                        <div class="title">
                            Predial Urbano
                        </div>
                        <h2>
                            CONSULTA TU CLAVE CASTASTRAL
                        </h2>
                        <div class="text">Ahorra tiempo, evita desplazamientos</div>
                    </div>
                    <div class="consult-form">
                        <form>
                            <div class="row clearfix">
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input id="mun" type="text" tabindex="1" name="mun" placeholder="MUN" required=""
                                        disabled="" maxlength="3" autocomplete="off" value="011">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input id="pob" type="text" tabindex="2" name="pob" placeholder="POB" required=""
                                        maxlength="3" autocomplete="off" value="">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input id="cua" type="text" tabindex="3" name="cua" placeholder="CUA" required=""
                                        maxlength="3" autocomplete="off" value="">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input id="man" type="text" tabindex="4" name="man" placeholder="MAN" required=""
                                        maxlength="3" autocomplete="off" value="">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input id="col" type="text" tabindex="5" name="col" placeholder="COL" required=""
                                        maxlength="3" autocomplete="off" value="">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4 col-4">
                                    <input id="uni" type="text" tabindex="6" name="uni" placeholder="UNI" required=""
                                        maxlength="3" autocomplete="off" value="">
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4"></div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4">
                                    <button class="theme-btn btn-style-two text-center centered" type="button">
                                        <i>Consultar</i>
                                        <span class="fa fa-search"></span>
                                    </button>
                                </div>
                                <div class="form-group col-lg-2 col-md-2 col-sm-4"></div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    </header>
</div>