import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Usuarios } from './Entidades/usuarios/usuarios.module';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  usuario = {} as Usuarios;

  constructor(private router: Router) { }

  canActivate() {
    if (!!(localStorage.getItem('usuario'))) {
      this.usuario = JSON.parse(atob(localStorage.getItem("usuario")));
      return true;
    }
    this.router.navigate(["/InicioSesion"]);
    return false;
  }
  // canActivate(
  //   next: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //   return true;
  // }

}
